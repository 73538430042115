import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FAQPage = () => {
    return (
        <Layout>
            <SEO title="Frequently Asked Questions" />
            <div className="max-w-7xl mx-auto pt-16 pb-24 px-4 sm:pt-24 sm:pb-32 sm:px-6 lg:px-8 font-sans text-green-main">
                <h1 className="text-xl text-center tracking-widest">Frequently Asked Questions</h1>
                <div className="mt-6 pt-10">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                How does this work?
                            </dt>
                            <dd className="mt-2 text-base leading-relaxed text-gray-500">
                                A preliminary meeting will be held either in person or via technology (Skype, FaceTime, Google Hangout, etc.) to set up a plan. We will discuss what you know and what you would like to know. I will give you an overview of my plan to put my skills to work for you. This plan will detail the particulars as to how many hours you would like me to research for you and for what time period. We will then set up another meeting. By this time you will have a copy of my research report. During this meeting we will meet to go over my research results and I will answer any further questions that you might then have.
                            </dd>
                        </div>
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                What do you expect from me?
                            </dt>
                            <dd className="mt-2 text-base leading-relaxed text-gray-500">
                                We will sign a contract that will state the particulars which were agreed upon during our preliminary meeting. This will be personalized to your needs. Besides the contract, I will agree to uphold the standards set forth in the Code of Ethics by the International Commission of the Accreditation of Professional Genealogists. (Please feel free to review the aforementioned <a className="text-green-dark underline" href="https://www.icapgen.org/home/professional-ethics/code-of-ethics/">Code of Ethics</a>.)
                            </dd>
                        </div>
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                What ignited your passion for family history?
                            </dt>
                            <dd className="mt-2 text-base leading-relaxed text-gray-500">
                                One of my very first and most fond memories is visiting my ancestral homeland. I am a first-generation American. My parents and siblings immigrated to the United States of America from Ireland. This provided a very different upbringing from those of my peers with deep southern roots. Knowing from a young age that my genealogy had a stark contrast to those around me, I desired to know more.
                            </dd>
                        </div>
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                When did you begin to research?
                            </dt>
                            <dd className="mt-2 text-base leading-relaxed text-gray-500">
                                When I was six years of age, my homesick mother began to teach me of her homeland, our ancestors, and the familial culture that was dear to her heart. It was from that moment on that I was immersed in stories, paperwork, pictures, and maps of Ireland. As I grew older I began to ask others about their families and sought out books to further explore their various family histories in historical contexts as well.
                            </dd>
                        </div>
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                When did you know this is what you wanted to do?
                            </dt>
                            <dd className="mt-2 text-base leading-relaxed text-gray-500">
                                Having grown to love the land of Erin, I hoped that one day I would be able to bring such a knowledge and love to others as they uncovered their own stories. The idea sparked in my head to do this as an occupation when I was 15 years old. Thus, Relative Interest was born.
                            </dd>
                        </div>
                        <div>
                            <dt className="text-lg leading-6 font-medium text-gray-900">
                                What are the standards for achieving a Certificate in Family History Research from Brigham Young University-Idaho?
                            </dt>
                            <dd className="mt-2 text-base leading-relaxed text-gray-500">
                                In order to be awarded a Certificate in Family History Research from Brigham Young University-Idaho, particular skills must be displayed through coursework and evaluation. These skills include but are not limited to: Mastery of the Genealogical Proof Standard, Proficiency in seeking and interpreting original and derivative documents, and Development of advanced paleographical skills.
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </Layout>
    )
}

export default FAQPage